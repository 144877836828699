<!-- Header with Background Image -->
<header *ngIf="withForm">
    <div class="container">
        <div class="col-lg-12 top-text">
            <h1>Never Pay Retail Again</h1>
            <span>Save 30-70% OFF Business Class Flights, Cruises & Hotels</span>
        </div>
        <div class="col-md-12 block-form pb-3 pb-md-0">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link active">Flights</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://businessclassguru.zenhotels.com/" target="_blank" rel="noopener noreferrer">Hotels</a>
                </li>
                <li class="nav-item" (click)="routeTo('/cruise')">
                    <a class="nav-link" routerlink="/cruise"  ng-reflect-router-link="/cruise" href="javascript:void(0)" (click)="routeTo('/cruise')">Cruises<label class="text-danger pr-1">NEW!</label></a>
                </li>
            </ul>
          
            <div class="form-bg">
                <div class="trip-type col-md-12 px-0">
                    <div class="d-flex justify-content-start">
                        <p
                                class="tripClick"
                                (click)="openTripTypeMobile()"
                        >
                        <span class="tripClick" *ngIf="queryFlightParams.tripType == 'Round'">Round-Trip </span>
                        <span class="tripClick" *ngIf="queryFlightParams.tripType == 'OneWay'">One-Way </span>
                        <span
                                    class="tripClick"
                                    *ngIf="queryFlightParams.tripType == 'MultiCity'">Multi-city </span>
                            <i class="tripClick fa fa-angle-down" aria-hidden="true"></i>
                        </p>
                        <p
                                class="passengersClick"
                                (click)="openTravelersMobile()"
                        >
                            {{ queryFlightParams.passengers }}
                            {{ queryFlightParams.passengers > 1 ? "Travelers" : "Traveler" }}
                            <i class="passengersClick fa fa-angle-down" aria-hidden="true"></i>
                        </p>
                        <p
                                class="cabinClick"
                                (click)="openCabinTypeMobile()"
                        >
                            {{
                            queryFlightParams.cabin == "PREMIUMECO"
                                ? "Premium Economy"
                                : capitalize(queryFlightParams.cabin)
                            }}
                            <span
                                    class="cabinClick"
                                    *ngIf="queryFlightParams.cabin != 'PREMIUMECO'"
                            >Class
                            </span>
                            <i class="cabinClick fa fa-angle-down" aria-hidden="true"></i>
                        </p>
                    </div>

                    <div class="d-none d-lg-flex justify-content-start bundle">
                        <span class="cursor-pointer"><img src="assets/img/icons/checkmark_wavy.svg" alt="check">Bundle + Save</span>

                        <label class="form-check form-check-inline">
                            Add a Hotel
                            <input class="form-check-input" type="checkbox" id="hotelCheckbox" [(ngModel)]="bundleSave.addHotel">
                            <i class="checkmark"></i>
                            </label>

                        <label class="form-check form-check-inline">
                            Add a Cruise
                            <input class="form-check-input" type="checkbox" id="cruiseCheckbox" [(ngModel)]="bundleSave.addCruise">
                            <i class="checkmark"></i>
                        </label>
                    </div>
                </div>

                <form
                        *ngIf="queryFlightParams.tripType == 'Round'"
                        [formGroup]="roundTripForm"
                        class="round-trip-form"
                >
                    <div class="row" id="round-trip-form">
                        <div class="col-6 col-md-3 padding-none form-label-group">
                            <input
                                    [ngClass]="{
                    'has-error':
                    !searchFormValid && !roundTripForm.get('flyingFrom').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    type="text"
                                    name="from-rt"
                                    id="from-rt"
                                    class="form-control"
                                    placeholder="Flying from?"
                                    aria-required="true"
                                    aria-invalid="false"
                                    appClearIcon
                                    autocomplete="off"
                                    [matAutocomplete]="autoFrom"
                                    formControlName="flyingFrom"
                                    [(ngModel)]="flight.from"
                                    (click)="$event.target.select(); openMobileFromToDialog(roundTripForm.get('flyingFrom'), roundTripForm.get('flyingTo'), $event, true);"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <label for="from-rt" [class.not-empty-control]="autoFrom._isOpen || !!roundTripForm.get('flyingFrom').value" class="icon-departure">
                                {{getFromToPlaceholder(flight.from, 'Flying from?')}}
                            </label>
                            <mat-autocomplete
                                #autoFrom="matAutocomplete"
                                (optionSelected)="saveSelectedItem(tripTypesEnum.Round, 'flyingFrom', $event); jumpToNextControl(flyingTo)"
                                [displayWith]="displayFn.bind(this)"
                            >
                                <mat-option
                                        *ngFor="let city of filteredFromData | async"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon" [class.xIcon-active]="flight.from?.length"></span>
                        </div>
                        <div class="col-6 col-md-3 padding-none form-label-group">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <div class="iconBig-flight d-block d-md-none"><img src="assets/img/icons/flightB.svg" alt="check"></div>
                            <input
                                    id="flyingToRound"
                                    [ngClass]="{
                    'has-error':
                    !searchFormValid && !roundTripForm.get('flyingTo').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingTo
                                    type="text"
                                    name="to-rt"
                                    id="to-rt"
                                    class="form-control"
                                    placeholder="Flying to?"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    [matAutocomplete]="autoTo"
                                    formControlName="flyingTo"
                                    [(ngModel)]="flight.to"
                                    (click)="$event.target.select(); openMobileFromToDialog(roundTripForm.get('flyingFrom'), roundTripForm.get('flyingTo'), $event, false);"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <label for="to-rt" [class.not-empty-control]="autoTo._isOpen || !!roundTripForm.get('flyingTo').value" class="icon-destination">
                                {{getFromToPlaceholder(flight.to, 'Flying to?')}}
                            </label>
                            <mat-autocomplete
                                #autoTo="matAutocomplete"
                                (optionSelected)="saveSelectedItem(tripTypesEnum.Round, 'flyingTo', $event); jumpToNextControl(dpDepart, 'flyingToRound')"
                                [displayWith]="displayFn.bind(this)"
                            >
                                <mat-option
                                        *ngFor="let city of filteredToData | async"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon" [class.xIcon-active]="flight.to?.length"></span>
                        </div>
                        <div class="col-12 col-md-6 padding-none">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <div class="iconBig-calendar d-block d-md-none"><img src="assets/img/icons/calendarB.svg" alt="check"></div>
                            <div class="row date-input">
                                <div
                                        class="col-6 col-md-6 padding-none depart-rt form-label-group"
                                        (click)="activeDepart = true"
                                        (appClickOutside)="onBlur('roundTripDepart')"
                                >
                                    <input
                                            id="roundTripDepart"
                                            type="text"
                                            name="depart-rt"
                                            id="depart-rt"
                                            class="form-control"
                                            placeholder="Depart"
                                            aria-required="true"
                                            aria-invalid="false"
                                            autocomplete="off"
                                            [ngClass]="{
                        'active-datepicker': activeDepart,
                        'has-error':
                        !searchFormValid &&
                        !roundTripForm.get('departDate').valid
                    }"
                                            [attr.disabled]="formSending ? '' : null"
                                            ngbDatepicker
                                            #dpDepart="ngbDatepicker"
                                            (appClickOutside)="
                        onClickedOutsideDp($event, dpDepart, 'roundTripDepart')
                    "
                                            [navigation]="navigation"
                                            [firstDayOfWeek]="firstDayOfWeek"
                                            [displayMonths]="displayMonths"
                                            (click)="dpDepart.toggle(); changeMustSubmit(true); openMobileFromToDialog(roundTripForm.get('flyingFrom'), roundTripForm.get('flyingTo'), $event, true);"
                                            [maxDate]="maxDate"
                                            [minDate]="minDateDepart"
                                            required
                                            formControlName="departDate"
                                            [readonly]="true"
                                            [(ngModel)]="flight.departDate"
                                            (ngModelChange)="
                        initReturnDatepicker(
                        dpDepart,
                        'roundTripReturn',
                        dpReturn
                        );
                        jumpToNextControl(dpReturn)
                    "
                                    />
                                    <label for="depart-rt" [class.not-empty-control]="dpDepart.isOpen() || !!roundTripForm.get('departDate').value?.day" class="icon-depart-date">
                                        {{getDatePlaceholder(roundTripForm.get('departDate').value, 'Depart')}}
                                    </label>
                                </div>
                                <div
                                        class="col-6 col-md-6 padding-none return-rt form-label-group"
                                        (click)="activeReturn = true"
                                        (appClickOutside)="onBlur('roundTripReturn')"
                                >
                                    <div class="bg-border-gray d-none d-md-block"></div>
                                    <input
                                            id="roundTripReturn"
                                            type="text"
                                            name="return-rt"
                                            id="return-rt"
                                            class="form-control"
                                            placeholder="Return"
                                            aria-required="true"
                                            aria-invalid="false"
                                            autocomplete="off"
                                            [ngClass]="{
                        'active-datepicker': activeReturn,
                        'has-error':
                        !searchFormValid &&
                        !roundTripForm.get('returnDate').valid
                    }"
                                            [attr.disabled]="formSending ? '' : null"
                                            ngbDatepicker
                                            #dpReturn="ngbDatepicker"
                                            (appClickOutside)="
                        onClickedOutsideDp($event, dpReturn, 'roundTripReturn')
                    "
                                            [navigation]="navigation"
                                            [firstDayOfWeek]="firstDayOfWeek"
                                            [displayMonths]="displayMonths"
                                            (click)="dpReturn.toggle(); changeMustSubmit(true);  openMobileFromToDialog(roundTripForm.get('flyingFrom'), roundTripForm.get('flyingTo'), $event, true);"
                                            [maxDate]="maxDate"
                                            [minDate]="minDateReturn"
                                            [(ngModel)]="returnDate"
                                            required
                                            formControlName="returnDate"
                                            [readonly]="true"
                                    />
                                    <label for="return-rt" [class.not-empty-control]="dpReturn.isOpen() || !!roundTripForm.get('returnDate').value?.day" class="icon-return-date">
                                        {{getDatePlaceholder(roundTripForm.get('returnDate').value, 'Return')}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form
                        *ngIf="queryFlightParams.tripType == 'OneWay'"
                        [formGroup]="oneWayForm"
                        class="oneWay-trip-form"
                >
                    <div class="row" id="one-way-form">
                        <div class="col-6 col-md-4 padding-none form-label-group">
                            <input
                                    [ngClass]="{
                    'has-error':
                    !searchFormValid && !oneWayForm.get('flyingFrom').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    type="text"
                                    name="from-rt"
                                    id="from-rt"
                                    class="form-control"
                                    placeholder="Flying from?"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    [(ngModel)]="flight.from"
                                    required
                                    formControlName="flyingFrom"
                                    [matAutocomplete]="autoFrom"
                                    (click)="$event.target.select(); openMobileFromToDialog(oneWayForm.get('flyingFrom'), oneWayForm.get('flyingTo'), $event, true);"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <label for="from-rt" [class.not-empty-control]="autoFrom._isOpen || !!oneWayForm.get('flyingFrom').value" class="icon-departure">
                                {{getFromToPlaceholder(flight.from, 'Flying from?')}}
                            </label>
                            <mat-autocomplete
                                #autoFrom="matAutocomplete"
                                (optionSelected)="saveSelectedItem(tripTypesEnum.OneWay, 'flyingFrom', $event); jumpToNextControl(flyingToOneWay)"
                                [displayWith]="displayFn.bind(this)"
                            >
                                <mat-option
                                        *ngFor="let city of filteredFromOneWayData | async"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon" [class.xIcon-active]="flight.from?.length"></span>
                        </div>
                        <div class="col-6 col-md-4 padding-none form-label-group">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <div class="iconBig-flight d-block d-md-none"><img src="assets/img/icons/flightB.svg" alt="check"></div>
                            <input
                                    id="flyingToOneWay"
                                    [ngClass]="{
                    'has-error':
                    !searchFormValid && !oneWayForm.get('flyingTo').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingToOneWay
                                    type="text"
                                    name="to-rt"
                                    id="to-rt"
                                    class="form-control"
                                    placeholder="Flying to?"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    [(ngModel)]="flight.to"
                                    required
                                    [matAutocomplete]="autoTo"
                                    formControlName="flyingTo"
                                    (click)="$event.target.select(); openMobileFromToDialog(oneWayForm.get('flyingFrom'), oneWayForm.get('flyingTo'), $event, false);"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <label for="to-rt" [class.not-empty-control]="autoTo._isOpen || !!oneWayForm.get('flyingTo').value" class="icon-destination">
                                {{getFromToPlaceholder(flight.to, 'Flying to?')}}
                            </label>
                            <mat-autocomplete
                                #autoTo="matAutocomplete"
                                (optionSelected)="saveSelectedItem(tripTypesEnum.OneWay, 'flyingTo', $event); jumpToNextControl(dpDepart, 'flyingToOneWay')"
                                [displayWith]="displayFn.bind(this)"
                            >
                                <mat-option
                                        *ngFor="let city of filteredToOneWayData | async"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon" [class.xIcon-active]="flight.to?.length"></span>
                        </div>
                        <div class="col-12 col-md-4 padding-none">
                            <div class="row date-input">
                                <div class="bg-border-gray d-none d-md-block"></div>
                                <div
                                        class="col-12 col-md-12 padding-none depart-rt form-label-group"
                                        (click)="activeDepart = true"
                                        (appClickOutside)="onBlur('oneWayDepart')"
                                >
                                    <input
                                            id="oneWayDepart"
                                            type="text"
                                            name="depart-rt"
                                            id="depart-rt"
                                            class="form-control"
                                            placeholder="Depart"
                                            aria-required="true"
                                            aria-invalid="false"
                                            autocomplete="off"
                                            [ngClass]="{
                        'active-datepicker': activeDepart,
                        'has-error':
                        !searchFormValid && !oneWayForm.get('departDate').valid
                    }"
                                            [attr.disabled]="formSending ? '' : null"
                                            ngbDatepicker
                                            #dpDepart="ngbDatepicker"
                                            (appClickOutside)="
                        onClickedOutsideDp($event, dpDepart, 'oneWayDepart')
                    "
                                            [navigation]="navigation"
                                            [firstDayOfWeek]="firstDayOfWeek"
                                            [displayMonths]="displayMonths"
                                            (click)="dpDepart.toggle(); changeMustSubmit(true); openMobileFromToDialog(oneWayForm.get('flyingFrom'), oneWayForm.get('flyingTo'), $event, true);"
                                            [maxDate]="maxDate"
                                            [minDate]="minDateDepart"
                                            [(ngModel)]="flight.departDate"
                                            required
                                            formControlName="departDate"
                                            [readonly]="true"
                                    />
                                    <label for="depart-rt" [class.not-empty-control]="dpDepart.isOpen() || !!oneWayForm.get('departDate').value?.day" class="icon-depart-date">
                                        {{getDatePlaceholder(oneWayForm.get('departDate').value, 'Depart')}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form
                        *ngIf="queryFlightParams.tripType == 'MultiCity'"
                        [formGroup]="multiCityForm"
                        class="multiCity-trip-form"
                >
                    <div class="row" id="multi-city-form">
                        <div class="flight-number col-12 d-md-none">Flight #1</div>
                        <div class="col-6 col-md-4 padding-none form-label-group">
                            <input
                                    [ngClass]="{
                    'has-error':
                    !searchFormValid && !multiCityForm.get('flyingFrom').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    type="text"
                                    name="from-rt"
                                    id="from-rt"
                                    class="form-control"
                                    placeholder="Flying from?"
                                    aria-required="true"
                                    aria-invalid="false"
                                    appClearIcon
                                    autocomplete="off"
                                    [(ngModel)]="flight.from"
                                    required
                                    formControlName="flyingFrom"
                                    [matAutocomplete]="autoFrom"
                                    (click)="$event.target.select();
                                    openMobileFromToDialog(multiCityForm.get('flyingFrom'), multiCityForm.get('flyingTo'), $event, true, 'departDate');"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <label for="from-rt" [class.not-empty-control]="autoFrom._isOpen || !!multiCityForm.get('flyingFrom').value" class="icon-departure">
                                {{getFromToPlaceholder(flight.from, 'Flying from?')}}
                            </label>
                            <mat-autocomplete
                                #autoFrom="matAutocomplete"
                                (optionSelected)="saveSelectedItem(tripTypesEnum.MultiCity, 'flyingFrom', $event); jumpToNextControl(flyingToMulty)"
                                [displayWith]="displayFn.bind(this)"
                            >
                                <mat-option
                                        *ngFor="let city of filteredFromMultiCityData | async"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon" [class.xIcon-active]="flight.from?.length"></span>
                        </div>
                        <div class="col-6 col-md-4 padding-none form-label-group">
                            <div class="iconBig-flight d-block d-md-none"><img src="assets/img/icons/flightB.svg" alt="check"></div>
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <input
                                    id="flyingToMultiCity"
                                    [ngClass]="{
                    'has-error':
                    !searchFormValid && !multiCityForm.get('flyingTo').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingToMulty
                                    type="text"
                                    name="to-rt"
                                    id="to-rt"
                                    class="form-control flying-to-control"
                                    placeholder="Flying to?"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    [(ngModel)]="flight.to"
                                    required
                                    [matAutocomplete]="autoTo"
                                    formControlName="flyingTo"
                                    (click)="$event.target.select();
                                    openMobileFromToDialog(multiCityForm.get('flyingFrom'), multiCityForm.get('flyingTo'), $event, false, 'departDate');"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <label for="to-rt" [class.not-empty-control]="autoTo._isOpen || !!multiCityForm.get('flyingTo').value" class="icon-destination">
                                {{getFromToPlaceholder(flight.to, 'Flying to?')}}
                            </label>
                            <mat-autocomplete
                                #autoTo="matAutocomplete"
                                (optionSelected)="saveSelectedItem(tripTypesEnum.MultiCity, 'flyingTo', $event);
                    jumpToNextControl(dpDepart, 'flyingToMultiCity')
                "
                                [displayWith]="displayFn.bind(this)"
                            >
                                <mat-option
                                        *ngFor="let city of filteredToMultiCityData | async"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon" [class.xIcon-active]="flight.to?.length"></span>
                        </div>
                        <div class="col-12 col-md-4 padding-none">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <div class="row date-input">
                                <div
                                        class="col-12 col-md-12 padding-none depart-rt form-label-group"
                                        (click)="activeDepart = true"
                                        (appClickOutside)="onBlur('multiCityDepart')"
                                >
                                    <input
                                            id="multiCityDepart"
                                            type="text"
                                            name="depart-rt"
                                            id="depart-rt"
                                            class="form-control"
                                            placeholder="Depart"
                                            aria-required="true"
                                            aria-invalid="false"
                                            autocomplete="off"
                                            [ngClass]="{
                        'active-datepicker': activeDepart,
                        'has-error':
                        !searchFormValid &&
                        !multiCityForm.get('departDate').valid
                    }"
                                            [attr.disabled]="formSending ? '' : null"
                                            ngbDatepicker
                                            #dpDepart="ngbDatepicker"
                                            (appClickOutside)="
                        onClickedOutsideDp($event, dpDepart, 'multiCityDepart')
                    "
                                            [navigation]="navigation"
                                            [firstDayOfWeek]="firstDayOfWeek"
                                            [displayMonths]="displayMonths"
                                            (click)="dpDepart.toggle(); changeMustSubmit(true); openMobileDepartReturnDateDialog(multiCityForm.get('departDate'), $event, true);"
                                            [maxDate]="maxDate"
                                            [minDate]="minDateDepart"
                                            formControlName="departDate"
                                            [(ngModel)]="flight.departDate"
                                            [readonly]="true"
                                            (ngModelChange)="
                        initDepartDatepicker();
                        jumpToNextControl(flyingFromMulty1)
                    "
                                    />
                                    <label for="depart-rt" [class.not-empty-control]="dpDepart.isOpen() || !!multiCityForm.get('departDate').value?.day" class="icon-depart-date">
                                        {{getDatePlaceholder(multiCityForm.get('departDate').value, 'Depart')}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="flight-number col-12 d-md-none">Flight #2</div>
                        <div class="col-6 col-md-4 padding-none form-label-group">
                            <input
                                    [ngClass]="{
                    'has-error':
                    !searchFormValid && !multiCityForm.get('flyingFrom1').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingFromMulty1
                                    type="text"
                                    name="from-rt"
                                    id="from-rt"
                                    class="form-control"
                                    placeholder="Flying from?"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    formControlName="flyingFrom1"
                                    [(ngModel)]="flight1.from"
                                    [matAutocomplete]="autoFrom1"
                                    (click)="$event.target.select();
                                    openMobileFromToDialog(multiCityForm.get('flyingFrom1'), multiCityForm.get('flyingTo1'), $event, true, 'departDate1');"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <label for="from-rt" [class.not-empty-control]="autoFrom1._isOpen || !!multiCityForm.get('flyingFrom1').value" class="icon-departure">
                                {{getFromToPlaceholder(flight1.from, 'Flying from?')}}
                            </label>
                            <mat-autocomplete
                                #autoFrom1="matAutocomplete"
                                (optionSelected)="saveSelectedItem(tripTypesEnum.MultiCity, 'flyingFrom1', $event); jumpToNextControl(flyingToMulty1)"
                                [displayWith]="displayFn.bind(this)"
                            >
                                <mat-option
                                        *ngFor="let city of filteredFrom1MultiCityData | async"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon" [class.xIcon-active]="flight1.from?.length"></span>
                        </div>
                        <div class="col-6 col-md-4 padding-none form-label-group">
                            <div class="iconBig-flight d-block d-md-none"><img src="assets/img/icons/flightB.svg" alt="check"></div>
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <input
                                    id="flyingTo1MultiCity"
                                    [ngClass]="{
                    'has-error':
                    !searchFormValid && !multiCityForm.get('flyingTo1').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingToMulty1
                                    type="text"
                                    name="to-rt"
                                    id="to-rt"
                                    class="form-control flying-to-control"
                                    placeholder="Flying to?"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    formControlName="flyingTo1"
                                    [(ngModel)]="flight1.to"
                                    [matAutocomplete]="autoTo1"
                                    (click)="$event.target.select();
                                    openMobileFromToDialog(multiCityForm.get('flyingFrom1'), multiCityForm.get('flyingTo1'), $event, false, 'departDate1');"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <label for="to-rt" [class.not-empty-control]="autoTo1._isOpen || !!multiCityForm.get('flyingTo1').value" class="icon-destination">
                                {{getFromToPlaceholder(flight1.to, 'Flying to?')}}
                            </label>
                            <mat-autocomplete
                                #autoTo1="matAutocomplete"
                                (optionSelected)="saveSelectedItem(tripTypesEnum.MultiCity, 'flyingTo1', $event);
                    jumpToNextControl(dpDepart1, 'flyingTo1MultiCity')
                "
                                [displayWith]="displayFn.bind(this)"
                            >
                                <mat-option
                                        *ngFor="let city of filteredTo1MultiCityData | async"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon" [class.xIcon-active]="flight1.to?.length"></span>
                        </div>
                        <div class="col-12 col-md-4 padding-none">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <div class="row date-input">
                                <div
                                        class="col-12 col-md-12 padding-none depart-rt form-label-group"
                                        (click)="activeDepart1 = true"
                                        (appClickOutside)="onBlur('multiCityDepart1')"
                                >
                                    <input
                                            id="multiCityDepart1"
                                            type="text"
                                            name="depart-rt"
                                            id="depart-rt"
                                            class="form-control"
                                            placeholder="Depart"
                                            aria-required="true"
                                            aria-invalid="false"
                                            autocomplete="off"
                                            [ngClass]="{
                        'active-datepicker': activeDepart1,
                        'has-error':
                        !searchFormValid &&
                        !multiCityForm.get('departDate1').valid
                    }"
                                            [attr.disabled]="formSending ? '' : null"
                                            ngbDatepicker
                                            #dpDepart1="ngbDatepicker"
                                            (appClickOutside)="
                        onClickedOutsideDp($event, dpDepart1, 'multiCityDepart1')
                    "
                                            [navigation]="navigation"
                                            [firstDayOfWeek]="firstDayOfWeek"
                                            [displayMonths]="displayMonths"
                                            (click)="dpDepart1.toggle(); changeMustSubmit(true); openMobileDepartReturnDateDialog(multiCityForm.get('departDate1'), $event, true);"
                                            [maxDate]="maxDate"
                                            [minDate]="minDateDepart1"
                                            formControlName="departDate1"
                                            [(ngModel)]="flight1.departDate"
                                            [readonly]="true"
                                            (ngModelChange)="
                        initDepartDatepicker();
                        jumpToNextControl(flyingFromMulty2)
                    "
                                    />
                                    <label for="depart-rt" [class.not-empty-control]="dpDepart1.isOpen() || !!multiCityForm.get('departDate1').value?.day" class="icon-depart-date">
                                        {{getDatePlaceholder(multiCityForm.get('departDate1').value, 'Depart')}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div [hidden]="!showThirdFlight" class="flight-number col-12 d-md-none">Flight #3</div>
                        <div
                                [hidden]="!showThirdFlight"
                                class="col-6 col-md-4 padding-none form-label-group"
                        >
                            <input
                                    [ngClass]="{
                    'has-error':
                    !searchFormValid && !multiCityForm.get('flyingFrom2').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingFromMulty2
                                    type="text"
                                    name="from-rt"
                                    id="from-rt"
                                    class="form-control"
                                    placeholder="Flying from?"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    formControlName="flyingFrom2"
                                    [(ngModel)]="flight2.from"
                                    [matAutocomplete]="autoFrom2"
                                    (click)="$event.target.select();
                                    openMobileFromToDialog(multiCityForm.get('flyingFrom2'), multiCityForm.get('flyingTo2'), $event, true, 'departDate2');"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <label for="from-rt" [class.not-empty-control]="autoFrom2._isOpen || !!multiCityForm.get('flyingFrom2').value" class="icon-departure">
                                {{getFromToPlaceholder(flight2.from, 'Flying from?')}}
                            </label>
                            <mat-autocomplete
                                #autoFrom2="matAutocomplete"
                                (optionSelected)="saveSelectedItem(tripTypesEnum.MultiCity, 'flyingFrom2', $event); jumpToNextControl(flyingToMulty2)"
                                [displayWith]="displayFn.bind(this)"
                            >
                                <mat-option
                                        *ngFor="let city of filteredFrom2MultiCityData | async"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon" [class.xIcon-active]="flight2.from?.length"></span>
                        </div>
                        <div
                                [hidden]="!showThirdFlight"
                                class="col-6 col-md-4 padding-none form-label-group"
                        >
                        <div class="iconBig-flight d-block d-md-none"><img src="assets/img/icons/flightB.svg" alt="check"></div>
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <input
                                    id="flyingTo2MultiCity"
                                    [ngClass]="{
                    'has-error':
                    !searchFormValid && !multiCityForm.get('flyingTo2').valid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    #flyingToMulty2
                                    type="text"
                                    name="to-rt"
                                    id="to-rt"
                                    class="form-control flying-to-control"
                                    placeholder="Flying to?"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    appClearIcon
                                    formControlName="flyingTo2"
                                    [(ngModel)]="flight2.to"
                                    [matAutocomplete]="autoTo2"
                                    (click)="$event.target.select();
                                    openMobileFromToDialog(multiCityForm.get('flyingFrom2'), multiCityForm.get('flyingTo2'), $event, false, 'departDate2');"
                                    (focus)="changeMustSubmit(false)"
                                    (focusout)="onFocusOutAirportInput($event)"
                            />
                            <label for="to-rt" [class.not-empty-control]="autoTo2._isOpen || !!multiCityForm.get('flyingTo2').value" class="icon-destination">
                                {{getFromToPlaceholder(flight2.to, 'Flying to?')}}
                            </label>
                            <mat-autocomplete
                                #autoTo2="matAutocomplete"
                                (optionSelected)="saveSelectedItem(tripTypesEnum.MultiCity, 'flyingTo2', $event);
                    jumpToNextControl(dpDepart2, 'flyingTo2MultiCity')
                "
                                [displayWith]="displayFn.bind(this)"
                            >
                                <mat-option
                                        *ngFor="let city of filteredTo2MultiCityData | async"
                                        [value]="city"
                                >
                                    <span>{{ city }}</span>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="xIcon" [class.xIcon-active]="flight2.to?.length"></span>
                        </div>
                        <div
                                [hidden]="!showThirdFlight"
                                class="col-12 col-md-4 padding-none"
                        >
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <div class="row date-input">
                                <div
                                        class="col-12 col-md-12 padding-none depart-rt form-label-group"
                                        (click)="activeDepart2 = true"
                                        (appClickOutside)="
                    activeDepart2 = false; onBlur('multiCityDepart2')
                    "
                                >
                                    <input
                                            id="multiCityDepart2"
                                            type="text"
                                            name="depart-rt"
                                            id="depart-rt"
                                            class="form-control"
                                            placeholder="Depart"
                                            aria-required="true"
                                            aria-invalid="false"
                                            autocomplete="off"
                                            [ngClass]="{
                        'active-datepicker': activeDepart2,
                        'has-error':
                        !searchFormValid &&
                        !multiCityForm.get('departDate2').valid
                    }"
                                            [attr.disabled]="formSending ? '' : null"
                                            ngbDatepicker
                                            #dpDepart2="ngbDatepicker"
                                            (appClickOutside)="
                        onClickedOutsideDp($event, dpDepart2, 'multiCityDepart2')
                    "
                                            [navigation]="navigation"
                                            [firstDayOfWeek]="firstDayOfWeek"
                                            [displayMonths]="displayMonths"
                                            (click)="dpDepart2.toggle(); changeMustSubmit(true); openMobileDepartReturnDateDialog(multiCityForm.get('departDate2'), $event, true);"
                                            [maxDate]="maxDate"
                                            [minDate]="minDateDepart2"
                                            formControlName="departDate2"
                                            [(ngModel)]="flight2.departDate"
                                            [readonly]="true"
                                    />
                                    <label for="depart-rt" [class.not-empty-control]="dpDepart2.isOpen() || !!multiCityForm.get('departDate2').value?.day" class="icon-depart-date">
                                        {{getDatePlaceholder(multiCityForm.get('departDate2').value, 'Depart')}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div
                                *ngIf="showAddFlightButton"
                                class="col-6 col-md-4 padding-none"
                        >
                            <button type="submit" class="btn-add" (click)="addFlight()">
                                Add Flight
                                <span>+</span>
                            </button>
                        </div>
                    </div>
                </form>
                <form [formGroup]="queryPanelForm">
                    <div
                            *ngIf="normalFlight"
                            class="row justify-content-md-center"
                            id="formLine"
                    >
                        <div class="col-md-3 padding-none form-label-group">
                            <input
                                    [ngClass]="{
                    'has-error':
                    !queryPanelForm.get('firstName').valid && !queryPanelValid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    type="text"
                                    name="reqName"
                                    id="reqName"
                                    class="form-control"
                                    placeholder="Name"
                                    maxlength="150"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    formControlName="firstName"
                                    [(ngModel)]="contactData.firstName"
                                    (click)="$event.target.select(); checkContactOpeningSource($event);"
                                    appClearIcon
                            />
                            <label for="reqName" class="icon-name" [class.not-empty-control]="!!queryPanelForm.get('firstName').value">Name</label>
                            <span class="xIcon" [class.xIcon-active]="contactData.firstName?.length"></span>
                        </div>
                        <div class="col-md-3 padding-none form-label-group">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <input
                                    [ngClass]="{
                    'has-error':
                    !queryPanelForm.get('email').valid && !queryPanelValid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    type="text"
                                    name="reqEmail"
                                    id="reqEmail"
                                    class="form-control"
                                    placeholder="Email"
                                    maxlength="150"
                                    aria-required="true"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    formControlName="email"
                                    [(ngModel)]="contactData.email"
                                    (click)="$event.target.select(); checkContactOpeningSource($event);"
                                    appClearIcon
                            />
                            <label for="reqEmail" class="icon-email" [class.not-empty-control]="!!queryPanelForm.get('email').value">Email</label>
                            <span class="xIcon" [class.xIcon-active]="contactData.email?.length"></span>
                        </div>

<!--                        <div class="col-md-3 padding-none form-label-group">-->
<!--                            <div class="bg-border-gray d-none d-md-block"></div>-->
<!--                            <input-->
<!--                                    [ngClass]="{-->
<!--                    'has-error':-->
<!--                    !queryPanelForm.get('phone').valid && !queryPanelValid-->
<!--                }"-->
<!--                                    [attr.disabled]="formSending ? '' : null"-->
<!--                                    type="text"-->
<!--                                    name="phone"-->
<!--                                    id="phone"-->
<!--                                    class="form-control"-->
<!--                                    placeholder="Phone"-->
<!--                                    maxlength="150"-->
<!--                                    aria-required="true"-->
<!--                                    aria-invalid="false"-->
<!--                                    autocomplete="off"-->
<!--                                    formControlName="phone"-->
<!--                                    [(ngModel)]="contactData.phone"-->
<!--                                    (click)="$event.target.select()"-->
<!--                                    [textMask]="{mask: phoneMask}"-->
<!--                                    appClearIcon-->
<!--                            />-->
<!--&lt;!&ndash;                            <label for="phone" class="withoutIcon">Phone</label>&ndash;&gt;-->
<!--                            <span class="xIcon"></span>-->
<!--                        </div>-->

                        <div class="col-md-3 padding-none form-label-group phone-label-group">
                            <div class="bg-border-gray d-none d-md-block"></div>
                            <input
                                    id="contactField"
                                    [ngClass]="{
                    'has-error':
                    !queryPanelForm.get('phone').valid && !queryPanelValid
                }"
                                    [attr.disabled]="formSending ? '' : null"
                                    type="tel"
                                    class="form-control"
                                    placeholder="Phone"
                                    formControlName="phone"
                                    [(ngModel)]="contactData.phone"
                                    #phoneSelect="ngx-phone-select"
                                    ngxPhoneSelect
                                    (click)="$event.target.select(); checkContactOpeningSource($event);"
                                    [textMask]="{ mask: phoneMask }"
                                    (countryChangeEvent)="countryChanged()"
                                    appClearIcon
                            />
                            <label for="contactField" [class.not-empty-control]="!!queryPanelForm.get('phone').value"
                                   class="with-phone">Phоne</label>
                            <span class="xIcon" [class.xIcon-active]="contactData.phone?.length"></span>
                        </div>

                        <div class="col-md-3 padding-none">
                            <button
                                    type="button"
                                    class="btn"
                                    (click)="executeRecaptchaCheckAction();"
                                    [attr.disabled]="formSending ? '' : null"
                            >
                                {{ submitBtnText }}
                            </button>
                            <small class="privacy-form"
                            >We value your
                                <a routerLink="/privacy-policy">privacy.</a>
                            </small>
                        </div>
                    </div>
                    <div *ngIf="!normalFlight">
                        <div id="errorBox" class="col-xs-12 pn errorBox container">
                            <div class="alert" role="alert">
                              We don’t offer flights to this destination or economy tickets.
                            </div>
                        </div>
                    </div>
                </form>

                <div class="d-none justify-content-start bundle">
                    <span class="cursor-pointer"><img src="assets/img/icons/checkmark_wavy.svg" alt="check">Bundle + Save</span>

                    <div class="d-inline-block">
                        <label class="form-check form-check-inline">
                            Add a Hotel
                            <input class="form-check-input" type="checkbox" id="hotelCheckbox">
                            <i class="checkmark"></i>
                        </label>

                        <label class="form-check form-check-inline">
                            Add a Cruise
                            <input class="form-check-input" type="checkbox" id="cruiseCheckbox">
                            <i class="checkmark"></i>
                        </label>
                    </div>
                </div>

            </div>

            <div class="row call-bottom-info">
                <div class="col-md-12 text-center">
                    <span>Call now for instant quotes<a href="tel:(888) 559-9822">(888) 559-9822</a></span>
                </div>
            </div>

            <div
                    *ngIf="passengersContent"
                    id="passengers"
                    (appClickOutside)="onClickedOutsidePassengers($event)"
            >
                <div class="cabin-tabs row">
                    <!-- <i class="arrow">▲</i> -->
                    <span class="cabin-name">Travelers:</span>
                    <ul class="d-none d-sm-block">
                        <li *ngFor="let count of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
                            <a
                                    name="a1"
                                    [ngClass]="{ active: queryFlightParams.passengers == count }"
                                    (click)="
                  queryFlightParams.passengers = count;
                  changeTravelers();
                  passengersContent = false
                "
                            >
                                <span>{{ count }}</span>
                            </a>
                        </li>
                    </ul>
                    <ul
                            class="d-block d-sm-none"
                            style="text-align: center; margin-left: 15px; margin-right: 15px;"
                    >
                        <li *ngFor="let count of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
                            <a
                                    name="a1"
                                    [ngStyle]="{
                  padding: count === 10 ? '13px 11px' : '13px 15px'
                }"
                                    [ngClass]="{ active: queryFlightParams.passengers == count }"
                                    (click)="
                  queryFlightParams.passengers = count;
                  changeTravelers();
                  passengersContent = false
                "
                            >
                                <span>{{ count }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!--            <div class="row cabin-close">-->
                <!--              <button type="button" class="btn" (click)="passengersContent=false">Close</button>-->
                <!--            </div>-->
            </div>
            <div
                    *ngIf="cabinContent"
                    id="cabin-type"
                    (appClickOutside)="onClickedOutsideCabinType($event)"
            >
                <div class="cabin-tabs row">
                    <!-- <i class="arrow">▲</i> -->
                    <!--              <span class="cabin-name">Select cabin:</span>-->
                    <ul>
                        <li id="BUSINESS">
                            <a
                                    name="a1"
                                    [ngClass]="{ active: queryFlightParams.cabin == 'BUSINESS' }"
                                    (click)="changeCabinType('BUSINESS'); cabinContent = false"
                            >
                                <span>Business</span>
                            </a>
                        </li>
                        <li id="FIRST">
                            <a
                                    name="a2"
                                    [ngClass]="{ active: queryFlightParams.cabin == 'FIRST' }"
                                    (click)="changeCabinType('FIRST'); cabinContent = false"
                            >
                                <span>First</span>
                            </a>
                        </li>
                        <li id="PREMIUMECO">
                            <a
                                    name="a3"
                                    [ngClass]="{ active: queryFlightParams.cabin == 'PREMIUMECO' }"
                                    (click)="changeCabinType('PREMIUMECO'); cabinContent = false"
                            >
                                <span>Premium Economy</span>
                            </a>
                        </li>
                        <li id="ECONOMY">
                            <a
                                    name="a4"
                                    [ngClass]="{ active: queryFlightParams.cabin == 'ECONOMY' }"
                                    (click)="changeCabinType('ECONOMY'); cabinContent = false"
                            >
                                <span>Economy</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!--            <div class="row cabin-close">-->
                <!--              <button type="button" class="btn" (click)="cabinContent=false">Close</button>-->
                <!--            </div>-->
            </div>

            <div
                    *ngIf="tripTypeContent"
                    id="trip-type"
                    (appClickOutside)="onClickedOutsideTripType($event)"
            >
                <div class="cabin-tabs row">
                    <!-- <i class="arrow">▲</i> -->
                    <!--              <span class="cabin-name">Select trip:</span>-->
                    <ul>
                        <li>
                            <a
                                    id="round-trip"
                                    valtype="round"
                                    [ngClass]="{ active: queryFlightParams.tripType == 'Round' }"
                                    (click)="
                  changeTripType('Round', roundTripForm);
                  tripTypeContent = false
                "
                            >
                                <span>Round-Trip</span>
                            </a>
                        </li>
                        <li>
                            <a
                                    id="one-way"
                                    valtype="oneway"
                                    [ngClass]="{ active: queryFlightParams.tripType == 'OneWay' }"
                                    (click)="
                  changeTripType('OneWay', oneWayForm); tripTypeContent = false
                "
                            >
                                <span>One-way</span>
                            </a>
                        </li>
                        <li>
                            <a
                                    id="multi-city"
                                    valtype="multi"
                                    [ngClass]="{
                  active: queryFlightParams.tripType == 'MultiCity'
                }"
                                    (click)="
                  changeTripType('MultiCity', multiCityForm);
                  tripTypeContent = false
                "
                            >
                                <span>Multi-city</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <!--            <div class="row cabin-close">-->
                <!--              <button type="button" class="btn" (click)="tripTypeContent=false">Close</button>-->
                <!--            </div>-->
            </div>
        </div>



        <div class="searchbox-down-sections-indents row pb-3 air-logos d-none d-md-flex">
            <div class="col">
              <img alt="Airfrance" src="assets/img/logos/flights/01.svg">
            </div>
            <div class="col">
              <img alt="Emirates" src="assets/img/logos/flights/02.svg">
            </div>
            <div class="col d-none d-md-block">
              <img alt="Cathay Pacific" src="assets/img/logos/flights/03.svg">
            </div>

            <div class="col  d-none d-md-block">
              <img alt="British Airways" src="assets/img/logos/flights/04.svg">
            </div>
            <div class="col">
              <img alt="Lufthansa" src="assets/img/logos/flights/05.svg">
            </div>
        </div>

    </div>
</header>

