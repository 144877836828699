<!-- Modal Contact US-->
<div class="contact-us-modal  animated fadeIn">
  <div class=" modal show" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">We love feedback!</h2>
        </div>
        <div class="contact-thank-text" [ngClass]="{'contact-failed': !success}">
          <h3 class="contact-us-response font-weight-normal">{{text}}</h3>
          <p *ngIf="success" class="font-weight-bold"><br/>**PLEASE CHECK YOUR JUNK/SPAM OR PROMOTIONS FOLDER**</p>
        </div>

        <div class="modal-footer">
          <button class="complete-button" mat-button (click)="onCloseDialog()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>
