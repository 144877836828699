import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-get-quote-complete-dialog',
  templateUrl: './get-quote-complete-dialog.component.html',
  styleUrls: ['./get-quote-complete-dialog.component.scss']
})
export class GetQuoteCompleteDialogComponent {
  constructor(
      public dialogRef: MatDialogRef<GetQuoteCompleteDialogComponent>) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
