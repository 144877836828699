/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./get-quote-complete-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./get-quote-complete-dialog.component";
var styles_GetQuoteCompleteDialogComponent = [i0.styles];
var RenderType_GetQuoteCompleteDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GetQuoteCompleteDialogComponent, data: {} });
export { RenderType_GetQuoteCompleteDialogComponent as RenderType_GetQuoteCompleteDialogComponent };
export function View_GetQuoteCompleteDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thank you for your request!"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Our team will be reaching out to you shortly. Please check your inbox."])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["**PLEASE CHECK YOUR JUNK/SPAM OR PROMOTIONS FOLDER**"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "actions-section mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["ok"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 12).disabled || null); var currVal_1 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_0, currVal_1); }); }
export function View_GetQuoteCompleteDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-get-quote-complete-dialog", [], null, null, null, View_GetQuoteCompleteDialogComponent_0, RenderType_GetQuoteCompleteDialogComponent)), i1.ɵdid(1, 49152, null, 0, i7.GetQuoteCompleteDialogComponent, [i2.MatDialogRef], null, null)], null, null); }
var GetQuoteCompleteDialogComponentNgFactory = i1.ɵccf("app-get-quote-complete-dialog", i7.GetQuoteCompleteDialogComponent, View_GetQuoteCompleteDialogComponent_Host_0, {}, {}, []);
export { GetQuoteCompleteDialogComponentNgFactory as GetQuoteCompleteDialogComponentNgFactory };
